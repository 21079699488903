* {
  font-family: "Noto Sans JP", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
}

@font-face {
  font-family: "Roboto+Slab";
  src: url("/fonts/RobotoSlab-Bold.ttf");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "Pacifico";
  src: url("/fonts/Pacifico-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: url("/fonts/Kanit-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "CorbenBold";
  src: url("/fonts/Corben-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "CorbenRegular";
  src: url("/fonts/Corben-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "MaliRegular";
  src: url("/fonts/Mali-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
